/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Company } from '../models/Company';
import type { CostCenter } from '../models/CostCenter';
import type { MeasurementUnit } from '../models/MeasurementUnit';
import type { Project } from '../models/Project';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CommonService {
    /**
     * @param q
     * @returns Company
     * @throws ApiError
     */
    public static commonCompaniesList(
        q?: string,
    ): CancelablePromise<Array<Company>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/common/companies/',
            query: {
                'q': q,
            },
        });
    }
    /**
     * @param search A search term.
     * @returns CostCenter
     * @throws ApiError
     */
    public static commonCostCentersList(
        search?: string,
    ): CancelablePromise<Array<CostCenter>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/common/cost-centers/',
            query: {
                'search': search,
            },
        });
    }
    /**
     * @param search A search term.
     * @returns MeasurementUnit
     * @throws ApiError
     */
    public static commonMeasurementUnitsList(
        search?: string,
    ): CancelablePromise<Array<MeasurementUnit>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/common/measurement-units/',
            query: {
                'search': search,
            },
        });
    }
    /**
     * @returns Project
     * @throws ApiError
     */
    public static commonProjectsList(): CancelablePromise<Array<Project>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/common/projects/',
        });
    }
}
