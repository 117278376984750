/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `Consumable` - Consumable
 * * `Nonconsumable` - Nonconsumable
 */
export enum AssetTypeEnum {
    CONSUMABLE = 'Consumable',
    NONCONSUMABLE = 'Nonconsumable',
}
