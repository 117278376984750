/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedPurchaseRequestListList } from '../models/PaginatedPurchaseRequestListList';
import type { PurchaseRequestCreate } from '../models/PurchaseRequestCreate';
import type { PurchaseRequestHistoryAction } from '../models/PurchaseRequestHistoryAction';
import type { PurchaseRequestList } from '../models/PurchaseRequestList';
import type { PurchaseRequestRetrieve } from '../models/PurchaseRequestRetrieve';
import type { PurchaseRequestUpdate } from '../models/PurchaseRequestUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PurchaseRequestsService {
    /**
     * @param createdBy
     * @param number
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param prName
     * @param project
     * @param workflowStep * `Draft` - Draft
     * * `OnCoordination` - On Coordination
     * * `OnApproval` - On Approval
     * * `Approved` - Approved
     * @returns PaginatedPurchaseRequestListList
     * @throws ApiError
     */
    public static purchaseRequestsList(
        createdBy?: string,
        number?: string,
        page?: number,
        pageSize?: number,
        prName?: string,
        project?: number,
        workflowStep?: 'Approved' | 'Draft' | 'OnApproval' | 'OnCoordination',
    ): CancelablePromise<PaginatedPurchaseRequestListList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-requests/',
            query: {
                'created_by': createdBy,
                'number': number,
                'page': page,
                'page_size': pageSize,
                'pr_name': prName,
                'project': project,
                'workflow_step': workflowStep,
            },
        });
    }
    /**
     * @param formData
     * @returns PurchaseRequestCreate
     * @throws ApiError
     */
    public static purchaseRequestsCreate(
        formData: PurchaseRequestCreate,
    ): CancelablePromise<PurchaseRequestCreate> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-requests/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this Запрос на покупку.
     * @returns PurchaseRequestRetrieve
     * @throws ApiError
     */
    public static purchaseRequestsRetrieve(
        id: number,
    ): CancelablePromise<PurchaseRequestRetrieve> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-requests/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Запрос на покупку.
     * @param formData
     * @returns PurchaseRequestUpdate
     * @throws ApiError
     */
    public static purchaseRequestsUpdate(
        id: number,
        formData: PurchaseRequestUpdate,
    ): CancelablePromise<PurchaseRequestUpdate> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/purchase-requests/{id}/',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this Запрос на покупку.
     * @returns any No response body
     * @throws ApiError
     */
    public static purchaseRequestsPartialUpdate(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/purchase-requests/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Запрос на покупку.
     * @returns void
     * @throws ApiError
     */
    public static purchaseRequestsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/purchase-requests/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param personId
     * @returns any No response body
     * @throws ApiError
     */
    public static purchaseRequestsApproveRequestCreate(
        personId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-requests/approve_request/',
            query: {
                'person_id': personId,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public static purchaseRequestsGenerateAllReportsCreate(): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-requests/generate_all_reports/',
        });
    }
    /**
     * @param requestId
     * @returns binary
     * @throws ApiError
     */
    public static purchaseRequestsGenerateReportRetrieve(
        requestId: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-requests/generate_report/',
            query: {
                'request_id': requestId,
            },
        });
    }
    /**
     * @param request
     * @returns PurchaseRequestHistoryAction
     * @throws ApiError
     */
    public static purchaseRequestsHistoryList(
        request?: number,
    ): CancelablePromise<Array<PurchaseRequestHistoryAction>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-requests/history/',
            query: {
                'request': request,
            },
        });
    }
    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static purchaseRequestsPositionFileUploadCreate(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-requests/position_file_upload/',
            errors: {
                400: `No response body`,
            },
        });
    }
    /**
     * @param requestId
     * @returns any No response body
     * @throws ApiError
     */
    public static purchaseRequestsPublishRequestCreate(
        requestId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-requests/publish_request/',
            query: {
                'request_id': requestId,
            },
        });
    }
    /**
     * @param personId
     * @param comment
     * @returns any No response body
     * @throws ApiError
     */
    public static purchaseRequestsReworkRequestCreate(
        personId: string,
        comment?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-requests/rework_request/',
            query: {
                'comment': comment,
                'person_id': personId,
            },
        });
    }
    /**
     * @param number
     * @returns PurchaseRequestList
     * @throws ApiError
     */
    public static purchaseRequestsSearchByNumberList(
        number: string,
    ): CancelablePromise<Array<PurchaseRequestList>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-requests/search-by-number/',
            query: {
                'number': number,
            },
        });
    }
}
