/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedReceivingListList } from '../models/PaginatedReceivingListList';
import type { ReceivingCreate } from '../models/ReceivingCreate';
import type { ReceivingHistoryAction } from '../models/ReceivingHistoryAction';
import type { ReceivingRetrieve } from '../models/ReceivingRetrieve';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ReceivingsService {
    /**
     * @param createdAt
     * @param createdBy
     * @param date
     * @param fromUser
     * @param number
     * @param order
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param toUser
     * @returns PaginatedReceivingListList
     * @throws ApiError
     */
    public static receivingsList(
        createdAt?: string,
        createdBy?: string,
        date?: string,
        fromUser?: string,
        number?: string,
        order?: number,
        page?: number,
        pageSize?: number,
        toUser?: string,
    ): CancelablePromise<PaginatedReceivingListList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/receivings/',
            query: {
                'created_at': createdAt,
                'created_by': createdBy,
                'date': date,
                'from_user': fromUser,
                'number': number,
                'order': order,
                'page': page,
                'page_size': pageSize,
                'to_user': toUser,
            },
        });
    }
    /**
     * @param formData
     * @returns ReceivingCreate
     * @throws ApiError
     */
    public static receivingsCreate(
        formData: ReceivingCreate,
    ): CancelablePromise<ReceivingCreate> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/receivings/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this Приемка.
     * @returns ReceivingRetrieve
     * @throws ApiError
     */
    public static receivingsRetrieve(
        id: number,
    ): CancelablePromise<ReceivingRetrieve> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/receivings/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Приемка.
     * @param formData
     * @returns ReceivingCreate
     * @throws ApiError
     */
    public static receivingsUpdate(
        id: number,
        formData: ReceivingCreate,
    ): CancelablePromise<ReceivingCreate> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/receivings/{id}/',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this Приемка.
     * @returns any No response body
     * @throws ApiError
     */
    public static receivingsPartialUpdate(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/receivings/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Приемка.
     * @returns void
     * @throws ApiError
     */
    public static receivingsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/receivings/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public static receivingsGenerateAllReportsCreate(): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/receivings/generate_all_reports/',
        });
    }
    /**
     * @param id
     * @returns binary
     * @throws ApiError
     */
    public static receivingsGenerateReportRetrieve(
        id: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/receivings/generate_report/',
            query: {
                'id': id,
            },
        });
    }
    /**
     * @param request
     * @returns ReceivingHistoryAction
     * @throws ApiError
     */
    public static receivingsHistoryList(
        request?: number,
    ): CancelablePromise<Array<ReceivingHistoryAction>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/receivings/history/',
            query: {
                'request': request,
            },
        });
    }
}
