import { notification } from "antd";
import { PurchaseOrderPositionRetrieve } from "./api";

export const DOMAIN = window.location.href.includes("localhost") ? "http://localhost:8000" : window.location.origin;

export enum CreateStepType {
  Form,
  Workflow,
}

export const getAccessToken = (): string => {
  return localStorage.getItem("access_token") ?? "";
};

export type NotificationType = "success" | "info" | "warning" | "error";

export const openNotificationWithIcon = (type: NotificationType, successDescription?: string) => {
  if (type === "success") {
    notification[type]({
      message: "Success",
      description: successDescription,
    });
  } else {
    notification[type]({
      message: "Error",
      description: "Something went wrong.",
    });
  }
};

export const currencies = [
  {
    label: "KZT",
    value: "KZT",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "RMB",
    value: "RMB",
  },
  {
    label: "EURO",
    value: "EURO",
  },
  {
    label: "RUB",
    value: "RUB",
  },
];

export const paymentWorkflowSteps = ["Draft", "OnCoordination", "Coordinated", "PartialPaid", "Paid"];
export const blockPaymentStepEdit = ["Coordinated", "PartialPaid", "Paid"];

export const transformPositions = (orderPositions: Array<PurchaseOrderPositionRetrieve>) => {
  const positions: any[] = [];

  orderPositions.forEach((position) => {
    if (position.type === "Default") {
      positions.push({
        id: String(position.id),
        prPositionId: position.pr_positions[0].id,
        prAssetName: position.pr_positions[0].asset_name,
        prMeasurementUnitName: position.pr_positions[0].measurement_unit_name,
        prCostCenterName: position.pr_positions[0].cost_center_name,
        prQuantity: position.pr_positions[0].quantity,
        poPositionId: position.id,
        poAssetName: position.asset,
        poMeasurementUnitId: position.measurement_unit,
        poQuantity: position.quantity,
        poPricePerItem: position.price,
        poTotalSum: position.sum,
        poCostCenterId: position.cost_center,
        type: "Default",
      });
    } else if (position.type === "Combined") {
      position.pr_positions.forEach((prPosition) => {
        positions.push({
          id: String(position.id),
          prPositionId: prPosition.id,
          prAssetName: prPosition.asset_name,
          prMeasurementUnitName: prPosition.measurement_unit_name,
          prCostCenterName: prPosition.cost_center_name,
          prQuantity: prPosition.quantity,
          poPositionId: position.id,
          poAssetName: position.asset,
          poMeasurementUnitId: position.measurement_unit,
          poQuantity: position.quantity,
          poPricePerItem: position.price,
          poTotalSum: position.sum,
          poCostCenterId: position.cost_center,
          type: "Combined",
        });
      });
    } else if (position.type === "Splitted") {
      let prPosition = position.pr_positions[0];
      let poPositions: any[] = [];

      orderPositions.forEach((p: any) => {
        if (!p.isUsed && p.type === "Splitted" && prPosition.id === p.pr_positions[0].id) {
          poPositions.push(p);
          p.isUsed = true;
        }
      });

      poPositions.forEach((position) => {
        positions.push({
          id: String(position.id),
          prPositionId: prPosition.id,
          prAssetName: prPosition.asset_name,
          prMeasurementUnitName: prPosition.measurement_unit_name,
          prCostCenterName: prPosition.cost_center_name,
          prQuantity: prPosition.quantity,
          poPositionId: position.id,
          poAssetName: position.asset,
          poMeasurementUnitId: position.measurement_unit,
          poQuantity: position.quantity,
          poPricePerItem: position.price,
          poTotalSum: position.sum,
          poCostCenterId: position.cost_center,
          type: "Splitted",
        });
      });
    }
  });

  return positions;
};
