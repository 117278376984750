/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Contract } from '../models/Contract';
import type { ContractCreate } from '../models/ContractCreate';
import type { ContractUpdate } from '../models/ContractUpdate';
import type { PaginatedContractListList } from '../models/PaginatedContractListList';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ContractsService {
    /**
     * @param company
     * @param contractNumber
     * @param number
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param startDate
     * @param subject
     * @returns PaginatedContractListList
     * @throws ApiError
     */
    public static contractsContractsList(
        company?: number,
        contractNumber?: string,
        number?: string,
        page?: number,
        pageSize?: number,
        startDate?: string,
        subject?: string,
    ): CancelablePromise<PaginatedContractListList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/contracts/contracts/',
            query: {
                'company': company,
                'contract_number': contractNumber,
                'number': number,
                'page': page,
                'page_size': pageSize,
                'start_date': startDate,
                'subject': subject,
            },
        });
    }
    /**
     * @param formData
     * @returns ContractCreate
     * @throws ApiError
     */
    public static contractsContractsCreate(
        formData: ContractCreate,
    ): CancelablePromise<ContractCreate> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/contracts/contracts/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this Договор.
     * @returns Contract
     * @throws ApiError
     */
    public static contractsContractsRetrieve(
        id: number,
    ): CancelablePromise<Contract> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/contracts/contracts/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Договор.
     * @param formData
     * @returns ContractUpdate
     * @throws ApiError
     */
    public static contractsContractsUpdate(
        id: number,
        formData: ContractUpdate,
    ): CancelablePromise<ContractUpdate> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/contracts/contracts/{id}/',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this Договор.
     * @returns any No response body
     * @throws ApiError
     */
    public static contractsContractsPartialUpdate(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/contracts/contracts/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Договор.
     * @returns void
     * @throws ApiError
     */
    public static contractsContractsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/contracts/contracts/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public static contractsContractsGenerateAllReportsCreate(): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/contracts/contracts/generate_all_reports/',
        });
    }
    /**
     * @param company
     * @returns any No response body
     * @throws ApiError
     */
    public static contractsContractsSearchByCompanyRetrieve(
        company: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/contracts/contracts/search_by_company/',
            query: {
                'company': company,
            },
        });
    }
}
