/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Email } from '../models/Email';
import type { PasswordToken } from '../models/PasswordToken';
import type { ResetToken } from '../models/ResetToken';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PasswordResetService {
    /**
     * An Api View which provides a method to request a password reset token based on an e-mail address
     *
     * Sends a signal reset_password_token_created when a reset token was created
     * @param requestBody
     * @returns Email
     * @throws ApiError
     */
    public static passwordResetCreate(
        requestBody: Email,
    ): CancelablePromise<Email> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/password_reset/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * An Api View which provides a method to reset a password based on a unique token
     * @param requestBody
     * @returns PasswordToken
     * @throws ApiError
     */
    public static passwordResetConfirmCreate(
        requestBody: PasswordToken,
    ): CancelablePromise<PasswordToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/password_reset/confirm/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * An Api View which provides a method to verify that a token is valid
     * @param requestBody
     * @returns ResetToken
     * @throws ApiError
     */
    public static passwordResetValidateTokenCreate(
        requestBody: ResetToken,
    ): CancelablePromise<ResetToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/password_reset/validate_token/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
