import React, { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import SimpleLoader from "./components/simple-loader";
import { ForbiddenPage, ProtectedRoute } from "./shared-components";

const withSuspense = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<SimpleLoader />}>
      <Component {...props} />
    </Suspense>
  );
};

const LoginLayout = withSuspense(lazy(() => import("./layouts/LoginLayout")));
const MainLayout = withSuspense(lazy(() => import("./layouts/MainLayout")));
const ForgotPasswordPage = withSuspense(
  lazy(() => import("./pages/Login/ForgotPasswordPage"))
);
const LoginPage = withSuspense(lazy(() => import("./pages/Login/LoginPage")));
const ResetPasswordPage = withSuspense(
  lazy(() => import("./pages/Login/ResetPasswordPage"))
);

const MyRequests = {
  List: withSuspense(lazy(() => import("./pages/MyRequests/List"))),
};

const MyTasks = {
  List: withSuspense(lazy(() => import("./pages/MyTasks/List"))),
};

const Payments = {
  List: withSuspense(lazy(() => import("./pages/Payments/List"))),
  Create: withSuspense(lazy(() => import("./pages/Payments/Create"))),
  Details: withSuspense(lazy(() => import("./pages/Payments/Details"))),
};

const Agreements = {
  List: withSuspense(lazy(() => import("./pages/Agreements/List"))),
  Create: withSuspense(lazy(() => import("./pages/Agreements/Create"))),
  Edit: withSuspense(lazy(() => import("./pages/Agreements/Edit"))),
};

const PurchaseOrder = {
  List: withSuspense(lazy(() => import("./pages/PurchaseOrder/List"))),
  Create: withSuspense(lazy(() => import("./pages/PurchaseOrder/Create"))),
  Edit: withSuspense(lazy(() => import("./pages/PurchaseOrder/Edit"))),
};

const PurchaseRequest = {
  List: withSuspense(lazy(() => import("./pages/PurchaseRequest/List"))),
  Create: withSuspense(lazy(() => import("./pages/PurchaseRequest/Create"))),
  Details: withSuspense(lazy(() => import("./pages/PurchaseRequest/Details"))),
};

const Receivings = {
  List: withSuspense(lazy(() => import("./pages/Receivings/List"))),
  Create: withSuspense(lazy(() => import("./pages/Receivings/Create"))),
  Details: withSuspense(lazy(() => import("./pages/Receivings/Details"))),
};

export const AppRoutes = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <ProtectedRoute element={<MyTasks.List />} />,
      },
      {
        path: "/purchase-requests",
        element: (
          <ProtectedRoute
            element={<PurchaseRequest.List />}
            roles={["PurchaseRequest", "Admin"]}
          />
        ),
      },
      {
        path: "/purchase-requests/create",
        element: (
          <ProtectedRoute
            element={<PurchaseRequest.Create />}
            roles={["PurchaseRequest", "Admin"]}
          />
        ),
      },
      {
        path: "/purchase-requests/edit/:id",
        element: (
          <ProtectedRoute
            element={<PurchaseRequest.Details />}
            roles={["PurchaseRequest", "Admin"]}
          />
        ),
      },
      {
        path: "/purchase-requests/:id",
        element: <ProtectedRoute element={<PurchaseRequest.Details />} />,
      },
      {
        path: "/agreements",
        element: (
          <ProtectedRoute
            element={<Agreements.List />}
            roles={["Contract", "Admin"]}
          />
        ),
      },
      {
        path: "/agreements/create",
        element: (
          <ProtectedRoute
            element={<Agreements.Create />}
            roles={["Contract", "Admin"]}
          />
        ),
      },
      {
        path: "/agreements/edit/:id",
        element: (
          <ProtectedRoute
            element={<Agreements.Edit />}
            roles={["Contract", "Admin"]}
          />
        ),
      },
      {
        path: "/agreements/:id",
        element: <ProtectedRoute element={<Agreements.Edit />} />,
      },
      {
        path: "/purchase-orders",
        element: (
          <ProtectedRoute
            element={<PurchaseOrder.List />}
            roles={["PurchaseOrder", "Admin"]}
          />
        ),
      },
      {
        path: "/purchase-orders/create",
        element: (
          <ProtectedRoute
            element={<PurchaseOrder.Create />}
            roles={["PurchaseOrder", "Admin"]}
          />
        ),
      },
      {
        path: "/purchase-orders/edit/:id",
        element: (
          <ProtectedRoute
            element={<PurchaseOrder.Edit />}
            roles={["PurchaseOrder", "Admin"]}
          />
        ),
      },
      {
        path: "/purchase-orders/:id",
        element: <ProtectedRoute element={<PurchaseOrder.Edit />} />,
      },
      {
        path: "/my-requests/",
        element: <ProtectedRoute element={<MyRequests.List />} />,
      },
      {
        path: "/payments/",
        element: (
          <ProtectedRoute
            element={<Payments.List />}
            roles={["Payments", "Admin"]}
          />
        ),
      },
      {
        path: "/payments/create",
        element: (
          <ProtectedRoute
            element={<Payments.Create />}
            roles={["Payments", "Admin"]}
          />
        ),
      },
      {
        path: "/payments/:id",
        element: <ProtectedRoute element={<Payments.Details />} />,
      },
      {
        path: "/receivings/",
        element: (
          <ProtectedRoute
            element={<Receivings.List />}
            roles={["Receiving", "Admin"]}
          />
        ),
      },
      {
        path: "/receivings/create",
        element: (
          <ProtectedRoute
            element={<Receivings.Create />}
            roles={["Receiving", "Admin"]}
          />
        ),
      },
      {
        path: "/receivings/:id",
        element: <ProtectedRoute element={<Receivings.Details />} />,
      },
      {
        path: "/403",
        element: <ForbiddenPage />,
      },
    ],
  },
  {
    element: <LoginLayout />,
    children: [
      {
        path: "/forgot-password",
        element: <ForgotPasswordPage />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/reset-password/:token",
        element: <ResetPasswordPage />,
      },
    ],
  },
]);
