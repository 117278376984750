/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateAttachment } from '../models/CreateAttachment';
import type { GetAttachment } from '../models/GetAttachment';
import type { PatchedGetAttachment } from '../models/PatchedGetAttachment';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AttachmentsService {
    /**
     * @returns GetAttachment
     * @throws ApiError
     */
    public static attachmentsList(): CancelablePromise<Array<GetAttachment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/attachments/',
        });
    }
    /**
     * @param formData
     * @returns CreateAttachment
     * @throws ApiError
     */
    public static attachmentsCreate(
        formData: CreateAttachment,
    ): CancelablePromise<CreateAttachment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/attachments/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this attachment.
     * @returns GetAttachment
     * @throws ApiError
     */
    public static attachmentsRetrieve(
        id: number,
    ): CancelablePromise<GetAttachment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/attachments/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this attachment.
     * @param formData
     * @returns GetAttachment
     * @throws ApiError
     */
    public static attachmentsUpdate(
        id: number,
        formData?: GetAttachment,
    ): CancelablePromise<GetAttachment> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/attachments/{id}/',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this attachment.
     * @param formData
     * @returns GetAttachment
     * @throws ApiError
     */
    public static attachmentsPartialUpdate(
        id: number,
        formData?: PatchedGetAttachment,
    ): CancelablePromise<GetAttachment> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/attachments/{id}/',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this attachment.
     * @returns void
     * @throws ApiError
     */
    public static attachmentsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/attachments/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this attachment.
     * @returns GetAttachment
     * @throws ApiError
     */
    public static attachmentsDownloadRetrieve(
        id: number,
    ): CancelablePromise<GetAttachment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/attachments/{id}/download/',
            path: {
                'id': id,
            },
        });
    }
}
