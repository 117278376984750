import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BellOutlined, GlobalOutlined, UserOutlined } from "@ant-design/icons";
import { Badge, Col, Image, Layout, Menu, MenuProps, Popover, Row } from "antd";
import enUS from "antd/locale/en_US";
import ruRu from "antd/locale/ru_RU";
import { Link, useNavigate } from "react-router-dom";
import accountsApi from "../../api/Accounts/AccountsApi";
import { MyTasksService } from "../../api";
import { useUser } from "../../hooks";
import { LayoutHeaderProps } from "./types";

const { Header } = Layout;

export const LayoutHeader = (props: LayoutHeaderProps) => {
  const { setLocale } = props;
  const { i18n, t } = useTranslation();
  const { user } = useUser();
  const fullName = (user && `${user.first_name} ${user.last_name}`) || "User";
  const [count, setCount] = useState<number>(0);
  const navigate = useNavigate();

  const handleChangeLanguage = async (language: string) => {
    await i18n.changeLanguage(language);
    localStorage.setItem("language", language);
    setLocale(language === "en" ? enUS : ruRu);
  };

  const handleLogout = () => {
    accountsApi.logout();
    navigate("/login");
  };

  const getCountCb = useCallback(async () => {
    const data = await MyTasksService.myTasksGetCountRetrieve();
    setCount(data.count);
  }, []);

  useEffect(() => {
    getCountCb().finally();
  }, [getCountCb]);

  const userMenuItems: MenuProps["items"] = [
    {
      key: "logout",
      label: t("General.Logout"),
      onClick: handleLogout,
    },
  ];
  const content = <Menu items={userMenuItems} />;

  const languageItems: MenuProps["items"] = [
    {
      key: "en",
      label: "English",
      onClick: () => handleChangeLanguage("en"),
    },
    {
      key: "ru",
      label: "Русский",
      onClick: () => handleChangeLanguage("ru"),
    },
  ];
  const contentLanguages = <Menu items={languageItems} />;

  return (
    <Header className="header">
      <Row justify={"space-between"} align={"middle"}>
        <Col>
          <Link to="/" className="logo">
            <Image
              src="/logo.png"
              alt={"Intertasco"}
              preview={false}
              width={32}
            />
            <span className="logo-text">INTERTASCO</span>
          </Link>
        </Col>
        <Col>
          <Popover
            placement="bottomRight"
            title={t("General.ChangeLanguage")}
            content={contentLanguages}
            trigger="click"
          >
            <GlobalOutlined style={{ fontSize: "24px", marginRight: "10px" }} />
          </Popover>

          <Link to={"/"}>
            <Badge count={count} style={{ marginRight: "15px" }}>
              <BellOutlined
                style={{
                  fontSize: "24px",
                  marginRight: "15px",
                  color: "white",
                }}
              />
            </Badge>
          </Link>

          <Popover
            placement="bottomRight"
            title={fullName}
            content={content}
            trigger="click"
          >
            <UserOutlined style={{ fontSize: "24px", marginRight: "8px" }} />
            {fullName}
          </Popover>
        </Col>
      </Row>
    </Header>
  );
};
