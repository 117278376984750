/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Asset } from '../models/Asset';
import type { PatchedAsset } from '../models/PatchedAsset';
import type { PatchedWarehouse } from '../models/PatchedWarehouse';
import type { Warehouse } from '../models/Warehouse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AssetsService {
    /**
     * @param search A search term.
     * @returns Asset
     * @throws ApiError
     */
    public static assetsAssetsList(
        search?: string,
    ): CancelablePromise<Array<Asset>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/assets/assets/',
            query: {
                'search': search,
            },
        });
    }
    /**
     * @param requestBody
     * @returns Asset
     * @throws ApiError
     */
    public static assetsAssetsCreate(
        requestBody: Asset,
    ): CancelablePromise<Asset> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/assets/assets/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Номенклатура.
     * @returns Asset
     * @throws ApiError
     */
    public static assetsAssetsRetrieve(
        id: number,
    ): CancelablePromise<Asset> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/assets/assets/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Номенклатура.
     * @param requestBody
     * @returns Asset
     * @throws ApiError
     */
    public static assetsAssetsUpdate(
        id: number,
        requestBody: Asset,
    ): CancelablePromise<Asset> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/assets/assets/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Номенклатура.
     * @param requestBody
     * @returns Asset
     * @throws ApiError
     */
    public static assetsAssetsPartialUpdate(
        id: number,
        requestBody?: PatchedAsset,
    ): CancelablePromise<Asset> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/assets/assets/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Номенклатура.
     * @returns void
     * @throws ApiError
     */
    public static assetsAssetsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/assets/assets/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param search A search term.
     * @returns Warehouse
     * @throws ApiError
     */
    public static assetsWarehousesList(
        search?: string,
    ): CancelablePromise<Array<Warehouse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/assets/warehouses/',
            query: {
                'search': search,
            },
        });
    }
    /**
     * @param requestBody
     * @returns Warehouse
     * @throws ApiError
     */
    public static assetsWarehousesCreate(
        requestBody: Warehouse,
    ): CancelablePromise<Warehouse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/assets/warehouses/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Склад.
     * @returns Warehouse
     * @throws ApiError
     */
    public static assetsWarehousesRetrieve(
        id: number,
    ): CancelablePromise<Warehouse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/assets/warehouses/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Склад.
     * @param requestBody
     * @returns Warehouse
     * @throws ApiError
     */
    public static assetsWarehousesUpdate(
        id: number,
        requestBody: Warehouse,
    ): CancelablePromise<Warehouse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/assets/warehouses/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Склад.
     * @param requestBody
     * @returns Warehouse
     * @throws ApiError
     */
    public static assetsWarehousesPartialUpdate(
        id: number,
        requestBody?: PatchedWarehouse,
    ): CancelablePromise<Warehouse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/assets/warehouses/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Склад.
     * @returns void
     * @throws ApiError
     */
    public static assetsWarehousesDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/assets/warehouses/{id}/',
            path: {
                'id': id,
            },
        });
    }
}
