/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedPaymentListList } from '../models/PaginatedPaymentListList';
import type { PaymentCreate } from '../models/PaymentCreate';
import type { PaymentHistoryAction } from '../models/PaymentHistoryAction';
import type { PaymentRetrieve } from '../models/PaymentRetrieve';
import type { UpdatePaymentRecord } from '../models/UpdatePaymentRecord';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PaymentsService {
    /**
     * @param company
     * @param number
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param purchaseOrderNumber
     * @param workflowStep * `Draft` - Draft
     * * `OnCoordination` - On Coordination
     * * `Coordinated` - Coordinated
     * * `PartialPaid` - PartialPaid
     * * `Paid` - Paid
     * @returns PaginatedPaymentListList
     * @throws ApiError
     */
    public static paymentsList(
        company?: string,
        number?: string,
        page?: number,
        pageSize?: number,
        purchaseOrderNumber?: string,
        workflowStep?: 'Coordinated' | 'Draft' | 'OnCoordination' | 'Paid' | 'PartialPaid',
    ): CancelablePromise<PaginatedPaymentListList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payments/',
            query: {
                'company': company,
                'number': number,
                'page': page,
                'page_size': pageSize,
                'purchase_order_number': purchaseOrderNumber,
                'workflow_step': workflowStep,
            },
        });
    }
    /**
     * @param requestBody
     * @returns PaymentCreate
     * @throws ApiError
     */
    public static paymentsCreate(
        requestBody: PaymentCreate,
    ): CancelablePromise<PaymentCreate> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Оплата.
     * @returns PaymentRetrieve
     * @throws ApiError
     */
    public static paymentsRetrieve(
        id: number,
    ): CancelablePromise<PaymentRetrieve> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payments/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Оплата.
     * @param requestBody
     * @returns PaymentCreate
     * @throws ApiError
     */
    public static paymentsUpdate(
        id: number,
        requestBody: PaymentCreate,
    ): CancelablePromise<PaymentCreate> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/payments/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id A unique integer value identifying this Оплата.
     * @returns any No response body
     * @throws ApiError
     */
    public static paymentsPartialUpdate(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/payments/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Оплата.
     * @returns void
     * @throws ApiError
     */
    public static paymentsDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/payments/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param paymentId
     * @returns any No response body
     * @throws ApiError
     */
    public static paymentsCoordinatePaymentCreate(
        paymentId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/coordinate_payment/',
            query: {
                'payment_id': paymentId,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public static paymentsGenerateAllReportsCreate(): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/generate_all_reports/',
        });
    }
    /**
     * @param payment
     * @returns PaymentHistoryAction
     * @throws ApiError
     */
    public static paymentsHistoryList(
        payment?: number,
    ): CancelablePromise<Array<PaymentHistoryAction>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payments/history/',
            query: {
                'payment': payment,
            },
        });
    }
    /**
     * @param paymentId
     * @returns any No response body
     * @throws ApiError
     */
    public static paymentsPublishPaymentCreate(
        paymentId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/publish_payment/',
            query: {
                'payment_id': paymentId,
            },
        });
    }
    /**
     * @param paymentId
     * @returns any No response body
     * @throws ApiError
     */
    public static paymentsReworkPaymentCreate(
        paymentId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/rework_payment/',
            query: {
                'payment_id': paymentId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any No response body
     * @throws ApiError
     */
    public static paymentsUpdatePaymentRecordsCreate(
        requestBody: UpdatePaymentRecord,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/update_payment_records/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
