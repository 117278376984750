/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedPurchaseOrderListList } from '../models/PaginatedPurchaseOrderListList';
import type { PurchaseOrderCreate } from '../models/PurchaseOrderCreate';
import type { PurchaseOrderCreateResponse } from '../models/PurchaseOrderCreateResponse';
import type { PurchaseOrderHistoryAction } from '../models/PurchaseOrderHistoryAction';
import type { PurchaseOrderList } from '../models/PurchaseOrderList';
import type { PurchaseOrderRetrieve } from '../models/PurchaseOrderRetrieve';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PurchaseOrdersService {
    /**
     * @param company
     * @param number
     * @param page A page number within the paginated result set.
     * @param pageSize Number of results to return per page.
     * @param workflowStep * `Draft` - Draft
     * * `OnCoordination` - On Coordination
     * * `Coordinated` - Coordinated
     * @returns PaginatedPurchaseOrderListList
     * @throws ApiError
     */
    public static purchaseOrdersList(
        company?: number,
        number?: string,
        page?: number,
        pageSize?: number,
        workflowStep?: 'Coordinated' | 'Draft' | 'OnCoordination',
    ): CancelablePromise<PaginatedPurchaseOrderListList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-orders/',
            query: {
                'company': company,
                'number': number,
                'page': page,
                'page_size': pageSize,
                'workflow_step': workflowStep,
            },
        });
    }
    /**
     * @param formData
     * @returns PurchaseOrderCreateResponse
     * @throws ApiError
     */
    public static purchaseOrdersCreate(
        formData: PurchaseOrderCreate,
    ): CancelablePromise<PurchaseOrderCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-orders/',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this Заказ на покупку.
     * @returns PurchaseOrderRetrieve
     * @throws ApiError
     */
    public static purchaseOrdersRetrieve(
        id: number,
    ): CancelablePromise<PurchaseOrderRetrieve> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-orders/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Заказ на покупку.
     * @param formData
     * @returns PurchaseOrderCreate
     * @throws ApiError
     */
    public static purchaseOrdersUpdate(
        id: number,
        formData: PurchaseOrderCreate,
    ): CancelablePromise<PurchaseOrderCreate> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/purchase-orders/{id}/',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * @param id A unique integer value identifying this Заказ на покупку.
     * @returns any No response body
     * @throws ApiError
     */
    public static purchaseOrdersPartialUpdate(
        id: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/purchase-orders/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id A unique integer value identifying this Заказ на покупку.
     * @returns void
     * @throws ApiError
     */
    public static purchaseOrdersDestroy(
        id: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/purchase-orders/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Retrieve a list of purchase orders.
     * @returns PurchaseOrderList
     * @throws ApiError
     */
    public static purchaseOrdersAllList(): CancelablePromise<Array<PurchaseOrderList>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-orders/all/',
        });
    }
    /**
     * @param orderId
     * @param personId
     * @returns any No response body
     * @throws ApiError
     */
    public static purchaseOrdersCoordinateOrderCreate(
        orderId: string,
        personId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-orders/coordinate_order/',
            query: {
                'order_id': orderId,
                'person_id': personId,
            },
        });
    }
    /**
     * @returns binary
     * @throws ApiError
     */
    public static purchaseOrdersGenerateAllReportsCreate(): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-orders/generate_all_reports/',
        });
    }
    /**
     * @param id
     * @returns binary
     * @throws ApiError
     */
    public static purchaseOrdersGenerateReportRetrieve(
        id: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-orders/generate_report/',
            query: {
                'id': id,
            },
        });
    }
    /**
     * @param order
     * @returns PurchaseOrderHistoryAction
     * @throws ApiError
     */
    public static purchaseOrdersHistoryList(
        order?: number,
    ): CancelablePromise<Array<PurchaseOrderHistoryAction>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/purchase-orders/history/',
            query: {
                'order': order,
            },
        });
    }
    /**
     * @param orderId
     * @returns any No response body
     * @throws ApiError
     */
    public static purchaseOrdersPublishOrderCreate(
        orderId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-orders/publish_order/',
            query: {
                'order_id': orderId,
            },
        });
    }
    /**
     * @param orderId
     * @returns any No response body
     * @throws ApiError
     */
    public static purchaseOrdersReworkOrderCreate(
        orderId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/purchase-orders/rework_order/',
            query: {
                'order_id': orderId,
            },
        });
    }
}
