/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `Draft` - Draft
 * * `OnCoordination` - On Coordination
 * * `Coordinated` - Coordinated
 */
export enum PurchaseOrderWorkflowStepEnum {
    DRAFT = 'Draft',
    ON_COORDINATION = 'OnCoordination',
    COORDINATED = 'Coordinated',
}
